// File: src/components/Footer.jsx
import React, { useEffect } from 'react';
import { Link } from 'gatsby';

const Footer = () => {
  useEffect(() => {
    // Adding DNS prefetch link if not already present
    if (!document.getElementById('ssb.pistik.dns-prefetch')) {
      const link = document.createElement('link');
      link.id = 'ssb.pistik.dns-prefetch';
      link.href = 'https://pistik.ssb.ee';
      link.rel = 'dns-prefetch';
      document.head.appendChild(link);
    }

    // Adding loader script if not already present
    if (!document.getElementById('ssb.pistik.loader')) {
      const script = document.createElement('script');
      script.id = 'ssb.pistik.loader';
      script.type = 'text/javascript';
      script.src = 'https://pistik.ssb.ee/app/public/js/main.js';
      script.async = true;

      document.head.appendChild(script);
    }

    // Adding event listener once the loader is ready
    const handleLoaderReady = (event) => {
      document.dispatchEvent(new CustomEvent('ssb.pistik.loader.load', {
        detail: {
          feature: 'evul-stamp',
          element: document.getElementById('ssb-evul-stamp'),
          options: {
            reg_code: '16141270',
            colour: '#000000',
          },
        },
      }));
    };

    document.addEventListener('ssb.pistik.loader.ready', handleLoaderReady, { once: true });

    return () => {
      document.removeEventListener('ssb.pistik.loader.ready', handleLoaderReady);
    };
  }, []);

  return (
    <footer className="footer ahjud-footer">
      <div className="content footer-content">
        <div className="footer-stamp">
          <Link href="https://evul.ee/liikmed/16141270-ahjud-pro-ou/sertifikaat" target="_blank">
            <div id="ssb-evul-stamp" />
          </Link>
        </div>

        <div className="footer-text">
          <p>
            <strong className="is-size-4 ahjud-font-syncopate">Ahjud Pro OÜ</strong>
            <br />
            <span className="is-size-6">Reg nr: 16141270</span>
            <br />
            <span className="is-size-6">Tel: +372 514 5930</span>
            <br />
            <span className="is-size-6">
              <a href="mailto:ahjudpro@gmail.com">ahjudpro@gmail.com</a>
            </span>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
