import React from 'react';
import { Link } from 'gatsby';
import logo from '../images/ahjud-logo.svg';

const Navbar = class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      navBarActiveClass: '',
    };
  }

  toggleHamburger() {
    const { active } = this.state;

    // toggle the active boolean in the state
    this.setState(
      {
        active: !active,
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        if (active) {
          this.setState({
            navBarActiveClass: 'is-active',
          });
        } else {
          this.setState({
            navBarActiveClass: '',
          });
        }
      },
    );
  }

  render() {
    const { navBarActiveClass } = this.state;
    return (
      <nav
        className="navbar is-transparent"
        role="navigation"
        aria-label="main-navigation"
        style={{ fontFamily: 'Syncopate' }}
      >
        <div className="container">
          <div className="navbar-brand">
            <Link to="/" className="navbar-item" title="Logo">
              <img
                src={logo}
                alt="Ahjud pro"
                style={{
                  width: '88px',
                  marginBottom: 0,
                  maxHeight: '75px',
                }}
              />
            </Link>
            {/* Hamburger menu */}
            <div
              className={`navbar-burger burger ${navBarActiveClass}`}
              data-target="navMenu"
              onClick={() => this.toggleHamburger()}
            >
              <span />
              <span />
              <span />
            </div>
          </div>
          <div
            id="navMenu"
            className={`nav-text navbar-menu ${navBarActiveClass}`}
          >
            <div className="navbar-start has-text-centered ml-5">
              <Link activeClassName="is-active is-tab" className="mr-3 navbar-item" to="/ettevottest">
                Ettevõttest
              </Link>
              <Link activeClassName="is-active is-tab" className="mr-3 navbar-item" to="/ahjud">
                Ahjud
              </Link>
              <Link activeClassName="is-active is-tab" className="mr-3 navbar-item" to="/pliidid">
                Pliidid
              </Link>
              <Link activeClassName="is-active is-tab" className="mr-3 navbar-item" to="/kaminad">
                Kaminad
              </Link>
              {/* <Link activeClassName="is-active is-tab" className="navbar-item" to="/kkk">
                KKK
              </Link> */}
              <Link activeClassName="is-active is-tab" className="navbar-item" to="/vota-yhendust">
                Võta ühendust
              </Link>
            </div>
          </div>
        </div>
      </nav>
    );
  }
};

export default Navbar;
